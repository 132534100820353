import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { PageHeader, Button, Tabs, message } from "antd"
import PersonalKPITable from "@components/configTable/PersonalKPITable"
import SiteKPITable from "@components/configTable/SiteKPITable"
import { SiteKPISetClient } from "@api"
import { navigate } from "gatsby"
import EditNameModal from "@components/configTable/EditNameModal"

const KPIConfiguration = ({ location }) => {
  const { TabPane } = Tabs
  const { state = {} } = location

  const [isVisible, setIsVisible] = useState(false)
  const [record, setRecord] = useState(state)
  const handleOnCancel = () => {
    return setIsVisible(false)
  }

  const getName = async () => {
    let client = new SiteKPISetClient()
    try {
      let result = await client.get2(record.id)
      if (result.isSucceeded) {
        setRecord(result.content)
      } else {
        throw Error(result.errors)
      }
    } catch (err) {
      message.error("System Error occur")
      navigate("/")
    }
  }

  useEffect(() => {
    getName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <Seo title="KPI Configuration" />
      <PageHeader
        onBack={() => window.history.back()}
        title={record ? record.name : ""}
        ghost={false}
        extra={
          <Button
            onClick={() => {
              setIsVisible(true)
            }}
          >
            Edit Title
          </Button>
        }
      />
      <EditNameModal
        title="Site KPI"
        apiClient={SiteKPISetClient}
        isVisible={isVisible}
        onCancel={handleOnCancel}
        record={record}
        refresh={() => {
          getName()
        }}
      />
      <Tabs type="card">
        <TabPane tab="Site KPI" key="siteKPI">
          <SiteKPITable id={record ? record.id : null} />
        </TabPane>
        <TabPane tab="Personal KPI" key="personalKPI">
          <PersonalKPITable id={record ? record.id : null} />
        </TabPane>
      </Tabs>
    </MainLayout>
  )
}

export default KPIConfiguration
